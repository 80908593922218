
.app {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.cutomForm {
  width: 300px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.p-field {
  margin-bottom: 15px;
}

h1 {
  text-align: center;
}

.beneficiaries {
    
}

.user-card  {

    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  .loginCard{
    /* background-color: red; */
  }

  .signup-widget:hover {
    background-color: #e7f1f8;
  }

  .signup-widget {
    background   : #fff;
    padding      : 30px 15px;
    text-align   : center;
    margin-bottom: 30px;
    border-radius: 15px;
  }
  
  .signup-widget p {
    margin-bottom: 0px;
  }

  .signup-stage {
    padding: 10px 9 3;
  }

  .signUpButton {
    background-color: #084B9B;
    color: #ffffff;
    border-color: #007BFF;
  }

  .signUpButton:hover{
    background-color: #95989A;
  }